import useSWR from "swr";
import {staticPage} from "grandus-lib/utils/fetches";
import {getAttachmentUrl} from "grandus-lib/utils/index";

import styles from "./static.page.module.scss";
import _, { groupBy, map, reverse, isEmpty, get } from "lodash";
import Slider from "react-slick";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import HeroImage from "components/heroImages/HeroImage";
import CategoryCrosssale from "components/category/CategoryCrosssale";

import HeadLine from "components/header/HeadLine";
import Contact from "components/contact";
import {CONTACT_PAGE_HASH} from "constants/CustomConstants";
import {fetchTranslations} from "utils/fetches";

const ArrowLeft = (props) => {
  const { className, onClick } = props;
  return (
    <button
      type="button"
      className={`${styles?.arrow} ${styles?.left}`}
      onClick={onClick}
    >
      <ArrowLeftOutlined />
    </button>
  );
};

const ArrowRight = (props) => {
  const { className, onClick } = props;
  return (
    <button
      type="button"
      className={`${styles?.arrow} ${styles?.right}`}
      onClick={onClick}
    >
      <ArrowRightOutlined />
    </button>
  );
};

const StaticPage = (props) => {
  const { page } = props;
  const { title, customCss = "", customJavascript = "", content = "", attachments = [], photo = {}, urlTitle = "" } = page;

  const { data: categoryData, error } = useSWR(`/api/pages/category/${urlTitle}`, (url) =>
    fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const categories = get(categoryData, "category.childCategories", []);

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const grouppedAttachmentsByHash = groupBy(attachments, 'hash');
  const groupedSortedAttachments = {};
  _(grouppedAttachmentsByHash).keys().sort().each(key => {
    groupedSortedAttachments[key] = grouppedAttachmentsByHash[key];
  });

  return (
    <div className={styles.staticPage}>
      {!isEmpty(photo) ? (
        <>
          <HeroImage
            photo={photo}
            size={"1200x375__cropped"}
            type={"jpg"}
            title={title}
          />
          <br />
        </>
      ) : (
        <>
          <HeadLine style={`page`}/>
          <div className={`container`}>
            <h1 className={styles.title}>{title}</h1>
          </div>
        </>
      )}
      <div className={`container`}>
        {customCss ? (
          <style
            dangerouslySetInnerHTML={{
              __html: customCss,
            }}
          />
        ) : null}
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        {customJavascript ? (
          <div
            dangerouslySetInnerHTML={{
              __html: customJavascript,
            }}
          />
        ) : null}
        {!isEmpty(categories) ? (
          <div className={styles.categories}>
            {categories.map((category, index) => {
              return (
                <div 
                  className={styles.category}
                  key={`static-page-category-${category.id}-${index}`} 
                >
                  <CategoryCrosssale 
                    category={category} 
                  />
                </div>
              )})}
          </div>
        ) : ("")}
        <div className={styles.attachments}>
          {
            map(groupedSortedAttachments, (grouppedAttachments, group) => {
              return(
                <div key={`attachment-group-${group}`}>
                  <div className={styles.content}>
                    <h3>{group != "null" ? group : ""}</h3>
                  </div>
                  <Slider autoplay {...settings}>
                    {reverse(grouppedAttachments).map((attachment, index) => {
                      
                      let name = attachment.name ? attachment.name : attachment.fileName;
                      let color = "#aaa";

                      if(name.indexOf('#') != -1){
                        color = name.substring(name.indexOf('#'));
                        name = name.substring(0, name.indexOf('#'));
                      }

                      return (
                          <div 
                            className={styles.attachmentWrapper}
                            key={`attachment-carousel-${group}-${index}`}
                          >
                            <a 
                              href={getAttachmentUrl(attachment)} 
                              className={styles.attachment}
                              target="_blank"
                              style={{
                                "backgroundColor" : color
                              }}
                            >
                              <div className={styles.content}>
                                <div className={styles.label}>{name}</div>
                                <svg width="37" height="25" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.8438 13.9688L18.5 21.3125L11.1563 13.9688H15.5625V8.09375H21.4375V13.9688H25.8438ZM29.2953 9.60656C28.799 7.10904 27.4516 4.86105 25.4829 3.24597C23.5143 1.63089 21.0464 0.748752 18.5 0.750001C14.2553 0.750001 10.5688 3.15875 8.73281 6.66906C6.57284 6.90575 4.57631 7.93158 3.12616 9.54978C1.67602 11.168 0.874376 13.2646 0.875 15.4375C0.875 17.7747 1.80346 20.0162 3.45612 21.6689C5.10879 23.3215 7.35028 24.25 9.6875 24.25H28.7812C29.7456 24.25 30.7006 24.0601 31.5916 23.691C32.4826 23.3219 33.2921 22.781 33.9741 22.0991C34.656 21.4171 35.1969 20.6076 35.566 19.7166C35.935 18.8256 36.125 17.8706 36.125 16.9063C36.125 13.0288 33.1141 9.88563 29.2953 9.60656Z" fill="black" /></svg>
                              </div>
                              <div className={styles.hoverContent}>
                                <svg width="37" height="25" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.8438 13.9688L18.5 21.3125L11.1563 13.9688H15.5625V8.09375H21.4375V13.9688H25.8438ZM29.2953 9.60656C28.799 7.10904 27.4516 4.86105 25.4829 3.24597C23.5143 1.63089 21.0464 0.748752 18.5 0.750001C14.2553 0.750001 10.5688 3.15875 8.73281 6.66906C6.57284 6.90575 4.57631 7.93158 3.12616 9.54978C1.67602 11.168 0.874376 13.2646 0.875 15.4375C0.875 17.7747 1.80346 20.0162 3.45612 21.6689C5.10879 23.3215 7.35028 24.25 9.6875 24.25H28.7812C29.7456 24.25 30.7006 24.0601 31.5916 23.691C32.4826 23.3219 33.2921 22.781 33.9741 22.0991C34.656 21.4171 35.1969 20.6076 35.566 19.7166C35.935 18.8256 36.125 17.8706 36.125 16.9063C36.125 13.0288 33.1141 9.88563 29.2953 9.60656Z" fill="white" /></svg>
                                <div className={styles.detail}>{attachment.extension}</div>
                              </div>
                            </a>
                          </div>
                      );
                    })}
                  </Slider>
                </div>
              )
            })
          }
        </div>
      </div>

      {page.hash === CONTACT_PAGE_HASH ? (
        <div className="container">
          <Contact/>
        </div>
      ) : ("")}
    </div>
  );
};

export async function getServerSideProps(context) {
  const {locale} = context;
  const props = await staticPage.serverSideProps(context);

  props.props = {
    ...props.props,
    notFound: isEmpty(props?.page),
    ...(await fetchTranslations(locale))
  }

  return props;
}

export default StaticPage;
