import style from "./ContactForm.module.scss";
import * as yup from "yup";

import {Form, Button, Row, Col, message as messageAntd} from "antd";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import {Formik, useFormikContext} from "formik";
import get from "lodash/get"
import {useTranslation} from "next-i18next";
import TagManager from "grandus-lib/utils/gtag";
import EnhancedEcommerce from "grandus-lib/utils/ecommerce";

const ContactForm = () => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue
  } = useFormikContext();
  const { t } = useTranslation();

  const addresses = [
    {value: "info@trnavatourism.sk", label: t("Turistické informačné centrum – Región Trnava"),},
    {value: "manazer.tic@trnavatourism.sk", label: t("Manažér turistického informačného centra"),},
    {value: "marketing@trnavatourism.sk", label: t("Marketingový manažér"),},
    {value: "webeditor@trnavatourism.sk", label: t("Manažér pre PR a kreatívu"),},
    {value: "office@trnavatourism.sk", label: t("Projektový manažér"),},
    {value: "marketing@trnavatourism.sk", label: t("Zastupujúci výkonný riaditeľ"),},
  ]

  return (
    <Form wrapperCol={{span: 24}} layout="horizontal" onFinish={handleSubmit} id={"form-contact"}>
      <Row gutter={[50, 15]}>
        <Col xs={24} lg={12}>
          <TextInput
            label={t("Vaše meno a priezvisko")}
            name="name"
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={t("Zadajte meno a priezvisko")}
          />
        </Col>
        <Col xs={24} lg={12}>
          <TextInput
            label={t("Vaša e-mailová adresa")}
            name="email"
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={t("priklad@gmail.com")}
          />
        </Col>

        <Col xs={24} lg={12}>
          <SelectInput
            label={t("Adresát")}
            id="toEmail"
            name="toEmail"
            fieldName="toEmail"
            values={values}
            touched={touched}
            errors={errors}
            onChange={(val) => {
              setFieldValue("toEmail", val);
            }}
            handleBlur={handleBlur}
            options={addresses}
            className={style.contactSelectInput}
          />
        </Col>

        <Col xs={24} lg={12}>
          <TextInput
            label={t("Predmet")}
            name="subject"
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={t("Zadajte predmet")}
          />
        </Col>

        <Col xs={24}>
          <TextInput
            label={t("Správa")}
            name="text"
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            placeholder={t("Napíšte správu")}
            type={'textarea'}
          />
        </Col>

        <Col xs={24}>
          <div className={style.textCenter}>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={isValid && isSubmitting}
                className={`btn-podujatia btn-podujatia--lg`}
                disabled={!dirty || !isValid}
              >
                {t("Odoslať")}
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

const Contact = () => {
  const { t } = useTranslation();

  const formProps = {
    enableReinitialize: true,
    initialValues: {
      email: '',
      name: '',
      subject: '',
      text: '',
      toEmail: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .nullable()
        .email(t("Zadajte email vo formáte priklad@gmail.com"))
        .required(t("Zadajte vašu emailovú adresu")),
      name: yup
        .string()
        .required(t("Zadajte vaše meno a priezvisko")),
      subject: yup
        .string()
        .required(t("Zadajte predmet")),
      text: yup
        .string(),
      toEmail: yup
        .string()
        .email(t("Neplatný email"))
        .required(t("Vyberte adresáta"))
    }),
    onSubmit: async (values, {setFieldError, setErrors, resetForm}) => {
      const {email, name, subject, text, toEmail} = values;
      const reqBody = {
        email: email,
        subject: subject,
        name: name,
        text: text,
        toEmail: toEmail
      };

      try {
        const response = await fetch(`/api/pages/contact`, {
          method: "POST",
          body: JSON.stringify(reqBody),
        }).then((data) => data.json());

        if (response) {
          const errorMessage = get(get(response, "messages.0"), "message");
          if (!errorMessage) {
            resetForm();
            messageAntd.success(
              t("Kontaktný formulár bol úspešne odoslaný"),
              5
            );
            TagManager.push(
              EnhancedEcommerce.form_submit({id: "form-contact"})
            );
          } else {
            messageAntd.error(errorMessage, 5);
          }
        }
      } catch (error) {
        console.error("An unexpected error happened:", error);
        setErrors(error.data.message);
      }

      return false;
    },
  }
  return (
    <div className={style.contactContainer}>
      <h3>{t("Napíšte nám")}</h3>
      <div className={style.contactWrapper}>
        <div className={style.contactBox}>
          <Formik {...formProps}>
            <ContactForm/>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Contact;