import styles from "./HeadLine.module.scss";

const HeadLine = ({
    style = "default"
}) => {
  return (
      <div className={`${styles.headLine} ${styles[style]}`}>
        <div className={styles.menuLine}></div>
        <div className={styles.gutterLine}></div>
      </div>
  );
};

export default HeadLine;
