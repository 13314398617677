import {getImageUrl} from "grandus-lib/utils/index";
import styles from "./HeroImage.module.scss";

const HeroImage = ({photo, size, type, title}) => {
    const imgUrl = photo
        ? getImageUrl(photo, size, type)
        : null;

    return (
        <div className={styles["product-hero-image"]} style={{backgroundImage: `url(${imgUrl})`}}>
            <div className={styles["product-hero-image__content-container"]}>
                <div className={styles["product-hero-image__overlay"]}></div>
                <div className="container">
                    <h1 className={styles["product-hero-image__title"]}>{title}</h1>
                </div>
            </div>
        </div>
    )
}

export default HeroImage;