import GridCard from "components/product/card/GridCard";

import styles from "components/product/grid/GridVariant.module.scss";

export const GridVariant1 = ({
    products, isEvent = false
  }) => {
    return (
      <div className={ `${styles.wrapper} ${styles.variant1}`}>
          <div className={styles.row}>
              <div className={styles.col}>
                  {products[0] ? (
                      <GridCard product={products[0]} isEvent={isEvent} />
                  ) : ("")}
              </div>
              <div className={styles.col}>
                  {products[1] ? (
                      <GridCard product={products[1]} isEvent={isEvent}/>
                  ) : ("")}
              </div>
          </div>
          <div className={styles.row}>
              <div className={styles.col}>
                  {products[2] ? (
                      <GridCard product={{...products[2], gridType: "horizontal"}} isEvent={isEvent}/>
                  ) : ("")}
              </div>
          </div>
      </div>
    );
  };

export const GridVariant2 = ({
    products, isEvent =false
  }) => {
    return (
        <div className={ `${styles.wrapper} ${styles.variant2}`}>
            <div className={styles.row}>
                <div className={styles.col}>
                    {products[0] ? (
                        <GridCard product={{...products[0], gridType: "vertical"}} isEvent={isEvent}/>
                    ) : ("")}
                </div>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {products[1] ? (
                            <GridCard product={products[1]} isEvent={isEvent}/>
                        ) : ("")}
                    </div>
                    <div className={styles.item}>
                        {products[2] ? (
                            <GridCard product={products[2]} isEvent={isEvent}/>
                        ) : ("")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const GridVariant3 = ({
    products, isEvent =false
  }) => {
    return (
        <div className={ `${styles.wrapper} ${styles.variant3}`}>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {products[0] ? (
                            <GridCard product={products[0]} isEvent={isEvent}/>
                        ) : ("")}
                    </div>
                    <div className={styles.item}>
                        {products[1] ? (
                            <GridCard product={products[1]} isEvent={isEvent}/>
                        ) : ("")}
                    </div>
                </div>
                <div className={styles.col}>
                    {products[2] ? (
                        <GridCard product={{...products[2], gridType: "vertical"}} isEvent={isEvent}/>
                    ) : ("")}
                </div>
            </div>
        </div>
    );
};

export const GridVariant4 = ({
    products, isEvent =false
  }) => {
    return (
        <div className={ `${styles.wrapper} ${styles.variant4}`}>
        <div className={styles.row}>
              <div className={styles.col}>
                  {products[0] ? (
                      <GridCard product={{...products[0], gridType: "horizontal"}} isEvent={isEvent}/>
                  ) : ("")}
              </div>
          </div>
          <div className={styles.row}>
              <div className={styles.col}>
                  {products[1] ? (
                      <GridCard product={products[1]} isEvent={isEvent}/>
                  ) : ("")}
              </div>
              <div className={styles.col}>
                  {products[2] ? (
                      <GridCard product={products[2]} isEvent={isEvent}/>
                  ) : ("")}
              </div>
          </div>
      </div>
    );
};
