import {isEmpty} from "lodash";
import Image from "grandus-lib/components-atomic/image/Image";
import styles from "./TagIcon.module.scss";

const TagIcon = (props) => {
  const {
    tags
  } = props;

  if (isEmpty(tags)) {
    return '';
  }

  const selectedTags = tags.filter((tag) => {
    return tag.photo;
  })

  if (isEmpty(selectedTags)) {
    return '';
  }

  return (
    <span className={styles['tag-icons']}>
      {selectedTags.map((tag, i) => {
        return (
          <span className={styles['tag-icons__icon']} key={`product-tag-${i}`}>
            <Image photo={tag.photo} size={'12x12__fit_height@2x'} type={'png'} className={styles['tag-icons__img']} />
          </span>
        )
      })}
    </span>
  )
}

export default TagIcon;