import Link from "next/link";
import {toUpper, get, find} from "lodash";
import { KIND_IMAGES_BY_HASH } from "constants/CustomConstants";
import { getAttachmentUrl } from "grandus-lib/utils/index";

import Image from "grandus-lib/components-atomic/image/Image";
import WishlistButton from "components/product/detail/wishlist/WishlistButton";

import styles from "components/product/card/ProductCard.module.scss";
import TagIcon from "components/tagIcon/TagIcon";

const ProductCard = ({
  id,
  name,
  urlTitle,
  photo,
  tags,
  gridType = "equal",
  gallery,
  kind,
  className = "",
    parameters,
    isEvent
}) => {
  let kindHash = toUpper(get(kind, "hash", "default"));
  let finalPhoto = photo;

  if (gridType == "equal" && get(gallery, 0)) {
    finalPhoto = gallery[0];
  }
  if (gridType == "horizontal" && get(gallery, 1)) {
    finalPhoto = gallery[1];
  }
  if (gridType == "vertical" && get(gallery, 2)) {
    finalPhoto = gallery[2];
  }

  let imageSize = "288x288__cropped";
  if (gridType == "vertical") {
    imageSize = "288x585__cropped";
  }
  if (gridType == "horizontal") {
    imageSize = "585x288__cropped";
  }

  // let finalPhoto = null;
  const quickInfoId = 26944
  const quickInfo = find(parameters, parameter => parameter.parameterId === quickInfoId)

  return (
    <div className={`${styles.productCard} ${className}`}>
      <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`}>
        <a>
          {finalPhoto ? (
            <Image photo={finalPhoto} size={imageSize} type={"jpg"} />
          ) : (
            <img
              src={getAttachmentUrl({
                fileUrl: get(
                  KIND_IMAGES_BY_HASH,
                  kindHash + "." + gridType,
                  ""
                ),
              })}
              title={name}
            />
          )}
          <h2 className={styles.name}>
            {name}<TagIcon tags={tags}/>
            {isEvent?
              <div className={styles.eventTime}>
               {quickInfo?.value}
              </div> :""
            }
          </h2>
        </a>
      </Link>
      <div className={styles.wishlistWrapper}>
        <WishlistButton productId={id} kind={kind} buttonType={`small`} />
      </div>
    </div>
  );
};

export default ProductCard;
