import {Form, Select} from "antd";
import get from "lodash/get"
import map from "lodash/map"

const SelectInput = (props) => {
  const {
    name: fieldName,
    label,
    touched,
    values,
    errors,
    onBlur,
    onChange,
    options = [],
    allowClear = false,
    disabled = false,
    validateStatus = "",
    help = null,
    showSearch,
    virtual = true,
    onSelect,
    dropdownRender,
    optionFilterProp,
    className,
  } = props;

  return (
    <Form.Item
      validateStatus={
        get(touched, fieldName)
          ? get(errors, fieldName)
            ? "error"
            : validateStatus
          : null
      }
      hasFeedback={get(touched, fieldName)}
      help={
        get(touched, fieldName) && get(errors, fieldName)
          ? get(errors, fieldName)
          : help
      }
    >
      <label htmlFor={fieldName}>{label}</label>
      <Select
        className={className}
        showSearch={showSearch}
        id={fieldName}
        name={fieldName}
        value={values[fieldName]}
        disabled={disabled}
        allowClear={allowClear}
        virtual={virtual}
        dropdownRender={dropdownRender}
        optionFilterProp={optionFilterProp}
        onChange={onChange}
        onSelect={onSelect}
        onBlur={onBlur}
      >
        {map(options, (option, index) => (
          <Select.Option
            value={get(option, "value")}
            key={`${fieldName}-option-${index}`}
            search={get(option, "search", get(option, "value"))}
          >
            {get(option, "label")}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectInput;
