import styles from "components/product/detail/wishlist/WishlistButton.module.scss";
import useWishlist from "grandus-lib/hooks/useWishlist";
import {get, toUpper} from "lodash";
import {useTranslation} from "next-i18next";

const WishlistButton = ({
  productId,
  kind,
  buttonType = "default" // or "small"
}) => {
  const { t } = useTranslation();
  const { isLoading, itemAdd, itemRemove, itemExists } = useWishlist();
  const text = isLoading
    ? t("Pracujem…")
    : !itemExists(productId)
      ? t("Pridať do obľúbených")
      : t("Odobrať z obľúbených");

  return (
    <div className={`${styles.wishlist} ${itemExists(productId) ? styles.active : ""} ${styles['item-'+toUpper(get(kind, "hash", "default"))]} ${styles[buttonType]}`}>
      <span
        onClick={(e) =>
          !isLoading
            ? itemExists(productId)
              ? itemRemove(productId)
              : itemAdd(productId)
            : null
        }
      >
        <span className={styles.icon}>
          {itemExists(productId) ? (
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9.23077L9.23077 1" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /><path d="M0.999698 1L9.23047 9.23077" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /></svg>
          ) : (
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.98859 3.19923C6.04068 -0.243231 1.58888 0.659012 1.05622 3.81106C0.4769 7.25352 4.48547 10.9361 7.98859 13C11.4917 10.9361 15.6247 7.26901 14.921 3.81106C14.2794 0.678373 9.93649 -0.243231 7.98859 3.19923Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /></svg>
          )}
        </span>
        <span className={styles.text}>
          {text}
        </span>
      </span>
    </div>
  );
};

export default WishlistButton;
