import { get } from "lodash";

import ProductCard from "components/product/card/ProductCard";

import styles from "components/product/card/GridCard.module.scss";

const GridCard = ({
  product,
    isEvent
}) => {
  return (
    get(product, "cardType") == "social-card" ? (
        <a className={styles.socialCard} href={product.link} target="_blank" title={product.title}>
          <img src={product.image} />
          <div className={styles.content}>
            <div
              dangerouslySetInnerHTML={{
                __html: product.icon
              }}
            />
          </div>
        </a>
    ) : (
        <ProductCard {...product} isEvent={isEvent}/>
    )
  )
};

export default GridCard;
