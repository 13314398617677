import { useRouter } from "next/router";
import map from "lodash/map";
import get from "lodash/get";
import {
  getCategoryLinkAttributesFromRouter,
  queryToQueryString,
  arrayToPath,
} from "grandus-lib/hooks/useFilter";
import InfiniteBase from "./InfiniteBase";

import styles from "./Infinite.module.scss";

import {chunk} from "lodash";
import { GridVariant1 } from "../grid/GridVariant";

const Infinite = ({
  productClassName = "",
  destinationElementId = null,
  stylesGrid,
  ...pagination
}) => {
  const router = useRouter();
  let defaultPageSize = 22;

  const getHref = (page, pageSize = defaultPageSize) => {
    let dataToChange = {};
    let toDelete = ["parameters", "category"];

    if (page > 1) {
      dataToChange.page = page;
    } else {
      toDelete.push("page");
    }

    const data = getCategoryLinkAttributesFromRouter(router, {
      dataToChange,
      toDelete,
    });

    router.push(data?.href, data?.as, { scroll: false, shallow: true });
  };

  const getKey = (index, data) => {


    const urlRest = queryToQueryString(
      router?.query,
      { page: index + 1, infinite: true, perPage: index >= 1 ? defaultPageSize + 2 : defaultPageSize },
      ["category", "parameters"],
      {
        encode: true,
      }
    );
    const urlParams = arrayToPath(get(router, "query.parameters", []));

    const url = `/api/lib/v1/products?${
      urlRest ? "&" + urlRest : ""
    }${urlParams ? "&param=" + urlParams : ""}`;
    return url;

  };


  return (
    <InfiniteBase
      {...pagination}
      handleGetKey={getKey}
      handleGetHref={getHref}
      className={styles?.wrapper}
      destinationElementId={destinationElementId}
      render={(data) =>
        map(chunk(data?.products, 3), (productsChunk, index) => {
          return (
            <div key={`grid-products-chunk-${index}`} className={stylesGrid.gridItem}>
                <GridVariant1 products={productsChunk} />
            </div>
          );
        })
      }
    />
  );
};

export default Infinite;
