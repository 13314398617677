import useSWR from "swr";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import ProductGrid from "components/product/grid/ProductGrid";

const CategoryCrosssale = ({category}) => {
    
    if(isEmpty(category)){ return null; }

    const { data: categoryData, error } = useSWR(`/api/pages/category/${category?.urlName}`, (url) =>
        fetch(url).then((r) => r.json()),
        {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        }
    );

    if(isEmpty(categoryData, 'products', [])){
        return null;
    }

    return (
        <>
            <h3>{get(categoryData, 'category.name', "")}</h3>
            <ProductGrid products={get(categoryData, 'products', [])}/>
        </>
    );
}

export default CategoryCrosssale;


